<template>
  <v-card
    border
    tile
  >
    <v-card-title class="d-flex">
      <div class="d-flex align-center fs-20">
        <div
          v-if="ordered && totalCount > 1"
          class="me-4"
        >
          <EditableSelectionRanking
            @move="$emit('move', $event)"
            :index="displayIndex"
            :length="totalCount"
            rank-suffix=""
          />
        </div>

        <span>
          {{ $t(source.name) }}
        </span>
      </div>

      <v-spacer />

      <ActionMenu
        @click:action:delete="destroy"
        :items="[
          {
            event: 'delete',
            title: 'Delete',
            avatar: 'delete',
          },
        ]"
        button-icon="more_vert"
        left
      />
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-row>
        <LabeledTextfield
          v-model="value.name"
          @input="changed = true"
          message="Name"
        />

        <LabeledTextfield
          v-model="value.family_dashboard_name"
          @input="changed = true"
          :max-length="40"
          message="Family dashboard name"
          show-counter
        />

        <LabeledTextarea
          v-model="value.description"
          @input="changed = true"
          message="Description"
        />
      </v-row>

      <v-divider class="my-6" />

      <template v-if="showPubliclyListed || showEligibilityRequired || showEnrollmentAssignable">
        <v-row>
          <LabeledSwitch
            v-if="showPubliclyListed"
            v-model="value.public"
            @input="changed = true"
            subtitle="Allow this funding source to be accessed publicly."
            title="Publicly listed"
          />

          <LabeledSwitch
            v-if="showEligibilityRequired"
            v-model="value.eligibility_required"
            @input="changed = true"
            subtitle="Require eligibility for this funding source to submit applications for this program and display in eligibility report."
            title="Eligibility required"
          />

          <LabeledSwitch
            v-if="showEnrollmentAssignable"
            v-model="value.enrollment_assignable"
            @input="changed = true"
            subtitle="Can be assigned to an Enrollment for Coordinated Enrollment systems"
            title="Enrollment assignable"
          />
        </v-row>

        <v-divider class="my-6" />
      </template>

      <!-- This version of CriteriaEditor does not have the validation found in QuestionForm -->
      <!-- Validation can be added here if needed -->
      <CriteriaEditor
        v-model="value.criteria"
        @change="changed = true"
        :schemas="schemas"
        formula
        message
      />
    </v-card-text>

    <v-card-actions class="bt-1 bc-very-light-gray py-4">
      <v-spacer />

      <v-btn
        @click="save"
        :disabled="!changed || notValid"
        :loading="processing"
        color="primary"
        variant="flat"
      >
        {{ $t('Save') }}
      </v-btn>
    </v-card-actions>
    <template v-if="showId">
      <v-divider />
      <div class="ma-4">ID: {{ source.id }}</div>
    </template>
  </v-card>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/specialist/services/bright_finder';
import CriteriaEditor from '@/shared/components/form/CriteriaEditor.vue';
import EditableSelectionRanking from '@/shared/components/subsidy/EditableSelectionRanking.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const emit = defineEmits(['destroy', 'move']);
const props = defineProps({
  deleteMessage: {
    type: String,
    default: 'Are you sure you want to remove this funding source? This cannot be undone.',
  },
  displayIndex: {
    type: Number,
    default: null,
  },
  ordered: {
    type: Boolean,
    default: false,
  },
  schemas: {
    type: Array,
    default: null,
  },
  showEligibilityRequired: {
    type: Boolean,
    default: true,
  },
  showEnrollmentAssignable: {
    type: Boolean,
    default: true,
  },
  showId: {
    type: Boolean,
    default: false,
  },
  showPubliclyListed: {
    type: Boolean,
    default: true,
  },
  source: {
    type: Object,
    default: null,
  },
  totalCount: {
    type: Number,
    default: null,
  },
});

const changed = ref(false);
const processing = ref(false);
const value = reactive(JSON.parse(JSON.stringify(props.source)));

const notValid = computed(() => {
  return value.criteria.some(
    (criteria) =>
      (!criteria.field || !criteria.comparator || !criteria.message) && !criteria.formula,
  );
});

async function destroy() {
  // eslint-disable-next-line no-alert
  if (confirm(props.deleteMessage)) {
    processing.value = true;
    await Api.organization.funding_source.destroy(value.id);
    emit('destroy');
    eventBus.chime('Removed');
  }
}

async function save() {
  processing.value = true;
  await Api.organization.funding_source.update(value.id, value);
  processing.value = false;
  changed.value = false;
  eventBus.chime('Saved');
}
</script>
