<template>
  <v-window-item value="provider-program-requirements">
    <template v-if="!processing">
      <div
        v-for="source in fundingSources"
        :key="source.id"
        class="mb-4"
      >
        <FundingSourceCard
          @destroy="loadFundingSources"
          :schemas="schemas"
          :source="source"
          :total-count="fundingSources.length"
          delete-message="Are you sure you want to delete this provider program requirement?"
          show-id
        />
      </div>

      <v-btn
        @click="draftFundingSource"
        class="mt-4"
        color="primary"
        prepend-icon="add"
      >
        {{ $t('New provider program requirements') }}
      </v-btn>

      <ResourceDialog
        @save="createFundingSource"
        :fields="fundingSourceFields"
        :processing="processing"
        action="new_provider_program_requirements"
        save-button-text="Create"
        title="Create provider program requirements"
      />
    </template>
  </v-window-item>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import FundingSourceCard from '@/admin/components/FundingSourceCard.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

import useRouterHelper from '@/shared/composables/useRouterHelper';
import { useRoute } from 'vue-router';

defineProps({
  schemas: {
    type: Array,
    default: null,
  },
});

const route = useRoute();
const { updateQuery } = useRouterHelper();

const funds = ref([]);
const fundingSources = ref([]);
const processing = ref(false);

const fundingSourceFields = computed(() => [{ required: true, text: 'Name', value: 'name' }]);

async function createFundingSource(newVal) {
  processing.value = true;

  const { data } = await Api.organization.funding_source.create({
    ...newVal,
    owner_id: route.params.subsidyProgramId,
    owner_type: 'SubsidyProgram',
    provider_program_requirements: true,
  });

  await updateQuery({ action: null });
  fundingSources.value.push(data);

  processing.value = false;
}

async function draftFundingSource() {
  await updateQuery({ action: 'new_provider_program_requirements' });
}

async function load() {
  processing.value = true;

  await loadFundingSources();
  await loadFunds();

  processing.value = false;
}

async function loadFundingSources() {
  const params = { owner_id: route.params.subsidyProgramId };
  const { data } = await Api.organization.funding_source.index(params);
  fundingSources.value = data.filter((source) => source.provider_program_requirements);
}

async function loadFunds() {
  const resp = await Api.organization.fund.index({});

  if (resp.data) {
    funds.value = resp.data;
  }
}

onMounted(async () => await load());
</script>
