import { createRouter, createWebHistory } from 'vue-router';
import store from '@/specialist/store';

import ActivityTypeIndex from '@/specialist/views/ActivityTypeIndex.vue';
import AdminSettings from '@/admin/views/SettingsConfiguration.vue';
import AnalyticsIndex from '@/specialist/views/AnalyticsIndex.vue';
import AnnouncementIndex from '@/specialist/views/AnnouncementIndex.vue';
import AnnouncementShow from '@/specialist/views/AnnouncementShow.vue';
import AnonymousFormShow from '@/shared/views/AnonymousFormShow.vue';
import AnonymousSurveyShow from '@/shared/views/AnonymousSurveyShow.vue';
import ApiKeyIndex from '@/admin/views/ApiKeyIndex.vue';
import AssessmentIndex from '@/specialist/views/AssessmentIndex.vue';
import AssessmentShow from '@/specialist/views/AssessmentShow.vue';
import AssetIndex from '@/admin/views/AssetIndex.vue';
import BackgroundCheckShow from '@/specialist/views/BackgroundCheckShow.vue';
import BankAccountShow from '@/specialist/views/BankAccountShow.vue';
import BrandIndex from '@/admin/views/brands/BrandIndex.vue';
import BrandNew from '@/admin/views/brands/BrandNew.vue';
import BrandShow from '@/admin/views/brands/BrandShow.vue';
import BusinessIndex from '@/specialist/views/BusinessIndex.vue';
import BusinessShow from '@/specialist/views/BusinessShow.vue';
import ChildSubsidyProgramAssignments from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramAssignments.vue';
import ChildSubsidyProgramFunding from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramFunding.vue';
import ChildSubsidyProgramIEPReferrals from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramIEPReferrals.vue';
import ChildSubsidyProgramIndex from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramIndex.vue';
import ChildSubsidyProgramMessaging from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramMessaging.vue';
import ChildSubsidyProgramQuestions from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramQuestions.vue';
import ChildSubsidyProgramSeats from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramSeats.vue';
import ChildSubsidyProgramSettings from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramSettings.vue';
import ChildSubsidyProgramShow from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramShow.vue';
import ChildSubsidyProgramStages from '@/admin/views/child-subsidy-programs/ChildSubsidyProgramStages.vue';
import CodeSent from '@/shared/views/CodeSent.vue';
import CohortCourseLesson from '@/specialist/views/learning/cohort/CohortCourseLesson.vue';
import CohortLessonAssignment from '@/specialist/views/learning/cohort/CohortLessonAssignment.vue';
import CohortShow from '@/specialist/views/learning/CohortShow.vue';
import ComplaintShow from '@/specialist/views/ComplaintShow.vue';
import ContentTemplateIndex from '@/admin/views/content-templates/ContentTemplateIndex.vue';
import CourseShow from '@/specialist/views/learning/CourseShow.vue';
import CustomFamilySubsidyIndex from '@/specialist/views/CustomFamilySubsidyIndex.vue';
import Dashboard from '@/specialist/views/Dashboard.vue';
import DashboardEdit from '@/specialist/views/DashboardEdit.vue';
import DataDashboard from '@/specialist/views/data/DataDashboard.vue';
import EligibilityProgramFunding from '@/admin/views/eligibility-programs/EligibilityProgramFunding.vue';
import EligibilityProgramIndex from '@/admin/views/eligibility-programs/EligibilityProgramIndex.vue';
import EligibilityProgramSettings from '@/admin/views/eligibility-programs/EligibilityProgramSettings.vue';
import EligibilityProgramShow from '@/admin/views/eligibility-programs/EligibilityProgramShow.vue';
import EnrollmentIndex from '@/specialist/views/EnrollmentIndex.vue';
import FallbackRouter from '@/shared/views/FallbackRouter.vue';
import FamilySubsidyProgramAssignments from '@/admin/views/family-subsidy-programs/FamilySubsidyProgramAssignments.vue';
import FamilySubsidyProgramFunding from '@/admin/views/family-subsidy-programs/FamilySubsidyProgramFunding.vue';
import FamilySubsidyProgramIndex from '@/admin/views/family-subsidy-programs/FamilySubsidyProgramIndex.vue';
import FamilySubsidyProgramMessaging from '@/admin/views/family-subsidy-programs/FamilySubsidyProgramMessaging.vue';
import FamilySubsidyProgramQuestions from '@/admin/views/family-subsidy-programs/FamilySubsidyProgramQuestions.vue';
import FamilySubsidyProgramSettings from '@/admin/views/family-subsidy-programs/FamilySubsidyProgramSettings.vue';
import FamilySubsidyProgramShow from '@/admin/views/family-subsidy-programs/FamilySubsidyProgramShow.vue';
import FamilySubsidyProgramStages from '@/admin/views/family-subsidy-programs/FamilySubsidyProgramStages.vue';
import FamilySubsidyShow from '@/specialist/views/FamilySubsidyShow.vue';
import FormIndex from '@/specialist/views/forms/FormIndex.vue';
import FormReview from '@/specialist/views/forms/FormReview.vue';
import FormShow from '@/specialist/views/forms/FormShow.vue';
import FundIndex from '@/admin/views/FundIndex.vue';
import GrantIndex from '@/specialist/views/grants/GrantIndex.vue';
import GrantProgramEligibility from '@/admin/views/grants/GrantProgramEligibility.vue';
import GrantProgramIndex from '@/admin/views/grants/GrantProgramIndex.vue';
import GrantProgramMessaging from '@/admin/views/grants/GrantProgramMessaging.vue';
import GrantProgramQuestions from '@/admin/views/grants/GrantProgramQuestions.vue';
import GrantProgramSettings from '@/admin/views/grants/GrantProgramSettings.vue';
import GrantProgramShow from '@/admin/views/grants/GrantProgramShow.vue';
import GrantProgramStages from '@/admin/views/grants/GrantProgramStages.vue';
import GrantShow from '@/specialist/views/grants/GrantShow.vue';
import GroupIndex from '@/specialist/views/GroupIndex.vue';
import GroupShow from '@/specialist/views/GroupShow.vue';
import JobConfigIndex from '@/admin/views/JobConfigIndex.vue';
import JobConfigShow from '@/admin/views/JobConfigShow.vue';
import LearningIndex from '@/specialist/views/learning/LearningIndex.vue';
import LessonShow from '@/specialist/views/learning/LessonShow.vue';
import LicenseShow from '@/specialist/views/LicenseShow.vue';
import LocalizedContentTemplateIndex from '@/admin/views/content-templates/LocalizedContentTemplateIndex.vue';
import LocalizedContentTemplateNew from '@/admin/views/content-templates/LocalizedContentTemplateNew.vue';
import LocalizedContentTemplateShow from '@/admin/views/content-templates/LocalizedContentTemplateShow.vue';
import LoginCodes from '@/shared/components/LoginCodes.vue';
import MessageIndex from '@/specialist/views/MessageIndex.vue';
import MessageTemplateIndex from '@/admin/views/MessageTemplateIndex.vue';
import MessageTemplateShow from '@/admin/views/MessageTemplateShow.vue';
import MessagingIndex from '@/specialist/views/MessagingIndex.vue';
import PageShow from '@/admin/views/PageShow.vue';
import PageTemplateNew from '@/admin/views/PageTemplateNew.vue';
import PageTemplateShow from '@/admin/views/PageTemplateShow.vue';
import PaymentIndex from '@/specialist/views/PaymentIndex.vue';
import Profile from '@/specialist/views/Profile.vue';
import ProviderIndex from '@/specialist/views/providers/ProviderIndex.vue';
import ProviderSearch from '@/specialist/views/providers/ProviderSearch.vue';
import ProviderShow from '@/specialist/views/providers/ProviderShow.vue';
import PublicProviderShow from '@/public/views/ProviderShow.vue';
import PublicWebpageShow from '@/shared/views/WebpageShow.vue';
import QuestionForm from '@/admin/components/QuestionForm.vue';
import RegisterFactor from '@/shared/views/RegisterFactor.vue';
import RegisterPassword from '@/shared/views/RegisterPassword.vue';
import ReportIndex from '@/specialist/views/ReportIndex.vue';
import ReportShow from '@/specialist/views/ReportShow.vue';
import RevisionIndex from '@/specialist/views/RevisionIndex.vue';
import SchemaAlerts from '@/admin/views/schemas/SchemaAlerts.vue';
import SchemaAssignmentConfigurations from '@/admin/views/schemas/SchemaAssignmentConfigurations.vue';
import SchemaDefinition from '@/admin/views/schemas/SchemaDefinition.vue';
import SchemaFunctionIndex from '@/admin/views/schemas/SchemaFunctionIndex.vue';
import SchemaFunctionShow from '@/admin/views/schemas/SchemaFunctionShow.vue';
import SchemaIndex from '@/admin/views/schemas/SchemaIndex.vue';
import SchemaLabels from '@/admin/views/schemas/SchemaLabels.vue';
import SchemaQuestions from '@/admin/views/schemas/SchemaQuestions.vue';
import SchemaSettings from '@/admin/views/schemas/SchemaSettings.vue';
import SchemaShares from '@/admin/views/schemas/SchemaShares.vue';
import SchemaShow from '@/admin/views/schemas/SchemaShow.vue';
import SchemaStages from '@/admin/views/schemas/SchemaStages.vue';
import SchemaUploads from '@/admin/views/schemas/SchemaUploads.vue';
import ScreenerProgramIndex from '@/admin/views/screener-programs/ScreenerProgramIndex.vue';
import ScreenerProgramQuestions from '@/admin/views/screener-programs/ScreenerProgramQuestions.vue';
import ScreenerProgramSettings from '@/admin/views/screener-programs/ScreenerProgramSettings.vue';
import ScreenerProgramShow from '@/admin/views/screener-programs/ScreenerProgramShow.vue';
import SignOut from '@/shared/views/SignOut.vue';
import SiteIndex from '@/admin/views/sites/SiteIndex.vue';
import SiteNew from '@/admin/views/sites/SiteNew.vue';
import SiteSettings from '@/admin/views/sites/SiteSettings.vue';
import SiteShow from '@/admin/views/sites/SiteShow.vue';
import SiteTemplateIndex from '@/admin/views/site-templates/SiteTemplateIndex.vue';
import SiteTemplateNew from '@/admin/views/site-templates/SiteTemplateNew.vue';
import SiteTemplateSettings from '@/admin/views/site-templates/SiteTemplateSettings.vue';
import SiteTemplateShow from '@/admin/views/site-templates/SiteTemplateShow.vue';
import SpecialistIndex from '@/specialist/views/SpecialistIndex.vue';
import SpecialistShow from '@/specialist/views/SpecialistShow.vue';
import StaffIndex from '@/specialist/views/StaffIndex.vue';
import StaffShow from '@/specialist/views/StaffShow.vue';
import SubsidyIndex from '@/specialist/views/SubsidyIndex.vue';
import SubsidyShow from '@/specialist/views/SubsidyShow.vue';
import SummaryConfigurationIndex from '@/specialist/views/SummaryConfigurationIndex.vue';
import SummaryConfigurationShow from '@/specialist/views/SummaryConfigurationShow.vue';
import SurveyIndex from '@/specialist/views/SurveyIndex.vue';
import SurveyShow from '@/specialist/views/SurveyShow.vue';
import TaskIndex from '@/specialist/views/TaskIndex.vue';
import TaskTypeIndex from '@/specialist/views/TaskTypeIndex.vue';
import TeamIndex from '@/specialist/views/TeamIndex.vue';
import TeamShow from '@/specialist/views/TeamShow.vue';
import TermIndex from '@/admin/views/TermIndex.vue';
import TermsAgreementIndex from '@/admin/views/TermsAgreementIndex.vue';
import TermsAgreementShow from '@/admin/views/TermsAgreementShow.vue';
import TranslationIndex from '@/admin/views/TranslationIndex.vue';
import UploadNew from '@/admin/views/UploadNew.vue';
import UploadShow from '@/admin/views/UploadShow.vue';
import WebpageIndex from '@/admin/views/WebpageIndex.vue';
import WebpageLanding from '@/shared/views/WebpageLanding.vue';
import WebpageShow from '@/admin/views/WebpageShow.vue';
import Welcome from '@/specialist/views/Welcome.vue';
import WorkflowEventIndex from '@/admin/views/workflow-events/WorkflowEventIndex.vue';
import WorkflowEventShow from '@/admin/views/workflow-events/WorkflowEventShow.vue';

export const routes = [
  {
    component: ActivityTypeIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'ActivityTypeIndex',
    path: '/activity_types',
  },

  {
    component: AdminSettings,
    meta: { depth: 11 },
    name: 'AdminSettings',
    path: '/admin/settings',
  },

  {
    component: AnalyticsIndex,
    meta: { depth: 3 },
    name: 'AnalyticsIndex',
    path: '/analytics',
  },

  {
    component: AnonymousFormShow,
    meta: {
      app_bar: false,
      public: true,
    },
    name: 'AnonymousFormShow',
    path: '/public/forms/:formSchemaId',
  },

  {
    component: AnonymousSurveyShow,
    meta: {
      app_bar: false,
      public: true,
    },
    name: 'AnonymousSurveyShow',
    path: '/public/surveys/:schemaId',
  },

  {
    component: ApiKeyIndex,
    meta: { depth: 3 },
    name: 'ApiKeyIndex',
    path: '/admin/api_keys',
  },

  {
    component: AssessmentIndex,
    meta: { depth: 11 },
    name: 'AssessmentIndex',
    path: '/assessments',
  },

  {
    component: AssessmentShow,
    meta: {
      app_bar: false,
      disableAppBar: true,
      depth: 12,
    },
    name: 'AssessmentShow',
    path: '/assessments/:assessmentId',
  },

  {
    component: AssetIndex,
    meta: { depth: 3 },
    name: 'AssetIndex',
    path: '/admin/assets',
  },

  {
    component: BackgroundCheckShow,
    meta: {
      app_bar: false,
      disableAppBar: true,
    },
    name: 'BackgroundCheckShow',
    path: '/background_checks/:id',
  },

  {
    component: BankAccountShow,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'BankAccountShow',
    path: '/bank_account',
    beforeEnter(_to, _from, next) {
      return store.state.profile?.org_billing_edit ? next() : next('/');
    },
  },

  {
    component: BrandIndex,
    meta: { depth: 11 },
    name: 'BrandIndex',
    path: '/admin/brands',
  },

  {
    component: BrandNew,
    meta: { depth: 12 },
    name: 'BrandNew',
    path: '/admin/brands/new',
  },

  {
    component: BrandShow,
    meta: { depth: 12 },
    name: 'BrandShow',
    path: '/admin/brands/:brandId',
  },

  {
    component: BusinessIndex,
    meta: { depth: 11 },
    name: 'BusinessIndex',
    path: '/businesses',
  },

  {
    component: BusinessShow,
    meta: {
      app_bar: false,
      disableAppBar: true,
      depth: 12,
    },
    name: 'BusinessShow',
    path: '/businesses/:businessId',
  },

  {
    component: CodeSent,
    meta: {
      background: 'bg-super-light-blue',
      public: true,
    },
    name: 'CodeSent',
    path: '/code_sent',
    props: (route) => ({ routeParams: route.params, routeQuery: route.query }),
  },

  {
    component: CohortCourseLesson,
    path: '/cohort/:cohortId/course/:courseId/lesson/:lessonId',
    children: [
      {
        component: CohortLessonAssignment,
        meta: { depth: 4 },
        name: 'CohortCourseLesson',
        path: 'assignment/:assignmentId',
      },
      {
        component: CohortLessonAssignment,
        meta: { depth: 4 },
        name: 'CohortLessonAssignment',
        path: 'assignment/:assignmentId',
      },
    ],
  },

  {
    component: CohortShow,
    meta: { depth: 3 },
    name: 'CohortShow',
    path: '/cohorts/:cohortId',
    beforeEnter(_to, _from, next) {
      return store.state.profile?.org_learning_access ? next() : next('/');
    },
  },

  {
    component: ComplaintShow,
    meta: {
      app_bar: false,
      disableAppBar: true,
      depth: 12,
    },
    name: 'ComplaintShow',
    path: '/complaints/:id',
  },

  {
    component: CourseShow,
    meta: { depth: 3 },
    name: 'CourseShow',
    path: '/courses/:courseId',
  },

  {
    component: ContentTemplateIndex,
    meta: { depth: 1 },
    name: 'ContentTemplateIndex',
    path: '/admin/content_templates',
  },

  {
    component: JobConfigIndex,
    meta: { depth: 1 },
    name: 'JobConfigIndex',
    path: '/admin/job_configs',
  },

  {
    component: JobConfigShow,
    meta: { depth: 2 },
    name: 'JobConfigShow',
    path: '/admin/job_configs/:jobConfigId',
  },

  {
    component: DashboardEdit,
    meta: { depth: 12 },
    name: 'DashboardEdit',
    path: '/dashboards/:dashboardId/edit',
  },

  {
    component: EligibilityProgramIndex,
    meta: { depth: 2 },
    name: 'EligibilityProgramIndex',
    path: '/admin/eligibility_programs',
  },

  {
    component: EligibilityProgramShow,
    meta: { depth: 3 },
    path: '/admin/eligibility_programs/:id',
    children: [
      {
        component: EligibilityProgramSettings,
        meta: { depth: 4 },
        name: 'EligibilityProgramShow',
        path: '',
      },
      {
        component: EligibilityProgramFunding,
        meta: { depth: 5 },
        name: 'EligibilityProgramFunding',
        path: 'eligibility',
      },
    ],
  },

  {
    component: EnrollmentIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'EnrollmentIndex',
    path: '/enrollments',
  },

  {
    component: CustomFamilySubsidyIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'FamilySubsidyIndex',
    path: '/family_subsidies',
  },

  {
    component: FamilySubsidyShow,
    meta: {
      app_bar: false,
      depth: 3,
      disableAppBar: true,
      fillHeight: true,
    },
    name: 'FamilySubsidyShow',
    path: '/family_subsidies/:id',
  },

  {
    component: FamilySubsidyProgramIndex,
    meta: { depth: 2 },
    name: 'FamilySubsidyProgramIndex',
    path: '/admin/family_subsidy_programs',
  },

  {
    component: FamilySubsidyProgramShow,
    meta: { depth: 3 },
    path: '/admin/family_subsidy_programs/:id',
    children: [
      {
        component: FamilySubsidyProgramSettings,
        meta: { depth: 4 },
        name: 'FamilySubsidyProgramShow',
        path: '',
      },
      {
        component: FamilySubsidyProgramQuestions,
        meta: { depth: 5 },
        name: 'FamilySubsidyProgramQuestions',
        path: 'questions',
      },
      {
        component: FamilySubsidyProgramFunding,
        meta: { depth: 5 },
        name: 'FamilySubsidyProgramFunding',
        path: 'funding',
      },
      {
        component: FamilySubsidyProgramStages,
        meta: { depth: 5 },
        name: 'FamilySubsidyProgramStages',
        path: 'stages',
      },
      {
        component: FamilySubsidyProgramAssignments,
        meta: { depth: 5 },
        name: 'FamilySubsidyProgramAssignments',
        path: 'assignments',
      },
      {
        component: FamilySubsidyProgramMessaging,
        meta: { depth: 5 },
        name: 'FamilySubsidyProgramMessaging',
        path: 'messaging',
      },
    ],
  },

  {
    component: FundIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'FundIndex',
    path: '/admin/funds',
  },

  {
    component: FormIndex,
    meta: {
      app_bar: false,
      depth: 3,
      disableAppBar: true,
    },
    name: 'FormIndex',
    path: '/forms',
  },

  {
    component: FormReview,
    meta: {
      app_bar: false,
      depth: 3,
      disableAppBar: true,
    },
    name: 'FormReview',
    path: '/forms/:id/review',
    beforeEnter(_to, _from, next) {
      return store.state.profile?.org_forms_review ? next() : next('/');
    },
  },

  {
    component: FormShow,
    meta: {
      app_bar: false,
      depth: 3,
      disableAppBar: true,
    },
    name: 'FormShow',
    path: '/forms/:id',
  },

  {
    component: GroupIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'GroupIndex',
    path: '/clients',
  },

  {
    component: GrantIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'GrantIndex',
    path: '/grants',
  },

  {
    component: GrantShow,
    meta: {
      app_bar: false,
      disableAppBar: true,
      depth: 3,
      fillHeight: true,
    },
    name: 'GrantShow',
    path: '/grants/:id',
  },

  {
    component: GrantProgramIndex,
    meta: { depth: 1 },
    name: 'GrantProgramIndex',
    path: '/admin/grant_programs',
  },

  {
    component: GrantProgramShow,
    meta: { depth: 3 },
    path: '/admin/grant_programs/:grantProgramId',
    children: [
      {
        component: GrantProgramSettings,
        meta: { depth: 4 },
        name: 'GrantProgramShow',
        path: '',
      },
      {
        component: GrantProgramQuestions,
        meta: { depth: 5 },
        name: 'GrantProgramQuestions',
        path: 'questions',
      },
      {
        component: GrantProgramEligibility,
        meta: { depth: 5 },
        name: 'GrantProgramEligibility',
        path: 'eligibility',
      },
      {
        component: GrantProgramStages,
        meta: { depth: 5 },
        name: 'GrantProgramStages',
        path: 'stages',
      },
      {
        component: GrantProgramMessaging,
        meta: { depth: 5 },
        name: 'GrantProgramMessaging',
        path: 'messaging',
      },
    ],
  },

  {
    component: GroupShow,
    meta: {
      app_bar: true,
      depth: 3,
    },
    name: 'GroupShow',
    path: '/clients/:groupId',
  },

  {
    component: LearningIndex,
    meta: { depth: 11 },
    name: 'LearningIndex',
    path: '/learning',
    beforeEnter(_to, _from, next) {
      return store.state.profile?.org_learning_access ? next() : next('/');
    },
  },

  {
    component: LessonShow,
    meta: { depth: 4 },
    name: 'LessonShow',
    path: '/courses/:courseId/lessons/:lessonId',
  },

  {
    component: LicenseShow,
    meta: {
      app_bar: false,
      disableAppBar: true,
      depth: 12,
    },
    name: 'LicenseShow',
    path: '/licenses/:id',
  },

  {
    component: LocalizedContentTemplateIndex,
    meta: { depth: 2 },
    name: 'LocalizedContentTemplateIndex',
    path: '/admin/content_templates/:contentTemplateId/localized_content_templates',
  },

  {
    component: LocalizedContentTemplateNew,
    meta: { depth: 2 },
    name: 'LocalizedContentTemplateNew',
    path: '/admin/content_templates/:contentTemplateId/localized_content_templates/new',
  },

  {
    component: LocalizedContentTemplateShow,
    meta: { depth: 2 },
    name: 'LocalizedContentTemplateShow',
    path: '/admin/content_templates/:contentTemplateId/localized_content_templates/:localizedContentTemplateId',
  },

  {
    component: MessagingIndex,
    path: '/messaging',
    children: [
      {
        component: MessageIndex,
        meta: { depth: 3 },
        name: 'MessageIndex',
        path: '',
      },
      {
        component: AnnouncementIndex,
        meta: { depth: 3 },
        name: 'AnnouncementIndex',
        path: 'announcements',
      },
      {
        component: AnnouncementShow,
        meta: {
          depth: 3,
          previous: {
            route: {
              name: 'AnnouncementIndex',
            },
          },
        },
        name: 'AnnouncementShow',
        path: 'announcements/:announcementId',
      },
    ],
  },

  {
    component: MessageTemplateIndex,
    meta: { depth: 3 },
    name: 'MessageTemplateIndex',
    path: '/admin/message_templates',
  },

  {
    component: MessageTemplateShow,
    meta: { depth: 4 },
    name: 'MessageTemplateShow',
    path: '/admin/message_templates/:messageTemplateId',
  },

  {
    component: PaymentIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'PaymentIndex',
    path: '/payments',
  },

  {
    component: ProviderIndex,
    meta: {
      app_bar: false,
      depth: 3,
    },
    name: 'ProviderIndex',
    path: '/providers',
  },

  {
    component: ProviderSearch,
    meta: {
      app_bar: false,
      depth: 2,
      fillHeight: true,
    },
    name: 'ProviderSearch',
    path: '/search',
  },

  {
    component: ProviderShow,
    meta: {
      app_bar: false,
      depth: 3,
    },
    name: 'ProviderShow',
    path: '/providers/:providerId',
    beforeEnter(_to, _from, next) {
      return store.state.profile?.org_providers_access ? next() : next('/');
    },
  },

  {
    component: ReportIndex,
    meta: {
      app_bar: false,
      depth: 3,
    },
    name: 'ReportIndex',
    path: '/reports',
  },

  {
    component: ReportShow,
    meta: {
      app_bar: false,
      depth: 3,
      disableAppBar: true,
    },
    name: 'ReportShow',
    path: '/reports/:reportId',
  },

  {
    component: RevisionIndex,
    meta: {
      app_bar: false,
      depth: 2,
      fillHeight: false,
    },
    name: 'RevisionIndex',
    path: '/revisions',
  },

  {
    component: SchemaIndex,
    meta: { depth: 1 },
    name: 'SchemaIndex',
    path: '/admin/schemas',
  },

  {
    component: SchemaShow,
    meta: { depth: 3 },
    path: '/admin/schemas/:schemaId',
    children: [
      {
        component: SchemaSettings,
        meta: { depth: 4 },
        name: 'SchemaShow',
        path: '',
      },
      {
        component: SchemaAlerts,
        meta: { depth: 4 },
        name: 'SchemaAlerts',
        path: 'alerts',
      },
      {
        component: SchemaAssignmentConfigurations,
        meta: { depth: 5 },
        name: 'SchemaAssignmentConfigurations',
        path: 'assignments',
      },
      {
        component: SchemaDefinition,
        meta: { depth: 5 },
        name: 'SchemaDefinition',
        path: 'definition',
      },
      {
        component: SchemaFunctionIndex,
        meta: { depth: 5 },
        name: 'SchemaFunctionIndex',
        path: 'functions',
      },
      {
        component: SchemaFunctionShow,
        meta: { depth: 5 },
        name: 'SchemaFunctionShow',
        path: 'functions/:schemaFunctionId',
      },
      {
        component: SchemaLabels,
        meta: { depth: 5 },
        name: 'SchemaLabels',
        path: 'labels',
      },
      {
        component: SchemaQuestions,
        meta: { depth: 5 },
        name: 'SchemaQuestions',
        path: 'questions',
      },
      {
        component: SchemaStages,
        meta: { depth: 4 },
        name: 'SchemaStages',
        path: 'stages',
      },
      {
        component: SchemaUploads,
        meta: { depth: 5 },
        name: 'SchemaUploads',
        path: 'uploads',
      },
      {
        component: SchemaShares,
        meta: { depth: 5 },
        name: 'SchemaShares',
        path: 'shares',
      },
    ],
  },

  {
    component: Profile,
    name: 'Profile',
    path: '/profile',
    meta: {
      app_bar: true,
      depth: 999,
    },
  },

  {
    component: PublicWebpageShow,
    name: 'PublicWebpageShow',
    path: '/pages/:webpageId',
    meta: {
      app_bar: true,
      depth: 0,
      public: true,
    },
  },

  {
    component: QuestionForm,
    meta: {
      app_bar: false,
      depth: 1,
      disableAppBar: true,
    },
    name: 'QuestionForm',
    path: '/admin/questions/:questionId',
  },

  {
    component: ScreenerProgramIndex,
    meta: { depth: 2 },
    name: 'ScreenerProgramIndex',
    path: '/admin/screener_programs',
  },

  {
    component: ScreenerProgramShow,
    meta: { depth: 3 },
    path: '/admin/screener_programs/:id',
    children: [
      {
        component: ScreenerProgramSettings,
        name: 'ScreenerProgramShow',
        meta: { depth: 4 },
        path: '',
      },
      {
        component: ScreenerProgramQuestions,
        name: 'ScreenerProgramQuestions',
        meta: { depth: 5 },
        path: '/admin/screener_programs/:id/questions',
      },
    ],
  },

  {
    component: SpecialistIndex,
    name: 'SpecialistIndex',
    path: '/members',
    meta: {
      app_bar: false,
      depth: 2,
    },
  },

  {
    component: SiteIndex,
    meta: { depth: 11 },
    name: 'SiteIndex',
    path: '/admin/sites',
  },

  {
    component: SiteNew,
    meta: { depth: 12 },
    name: 'SiteNew',
    path: '/admin/sites/new',
  },

  {
    component: SiteShow,
    meta: { depth: 12 },
    path: '/admin/sites/:siteId',
    children: [
      {
        component: SiteSettings,
        meta: { depth: 13 },
        name: 'SiteShow',
        path: '',
      },
      {
        component: PageShow,
        meta: { depth: 13 },
        name: 'PageShow',
        path: 'pages/:pageId',
      },
    ],
  },

  {
    component: SiteSettings,
    meta: { depth: 13 },
    name: 'SiteSettings',
    path: '/admin/sites/:siteId/settings',
  },

  {
    component: SiteTemplateIndex,
    meta: { depth: 1 },
    name: 'SiteTemplateIndex',
    path: '/admin/templates',
  },

  {
    component: SiteTemplateNew,
    meta: { depth: 2 },
    name: 'SiteTemplateNew',
    path: '/admin/templates/new',
  },

  {
    component: SiteTemplateShow,
    path: '/admin/templates/:siteTemplateId',
    children: [
      {
        component: SiteTemplateSettings,
        meta: { depth: 3 },
        name: 'SiteTemplateShow',
        path: '',
      },
      {
        component: PageTemplateNew,
        meta: { depth: 4 },
        name: 'PageTemplateNew',
        path: 'pages/new',
      },
      {
        component: PageTemplateShow,
        meta: { depth: 4 },
        name: 'PageTemplateShow',
        path: 'pages/:pageTemplateId',
      },
    ],
  },

  {
    component: SpecialistShow,
    meta: {
      disableAppBar: true,
      depth: 3,
      previous: {
        route: {
          name: 'SpecialistIndex',
        },
      },
    },
    name: 'SpecialistShow',
    path: '/members/:id',
    beforeEnter(_to, _from, next) {
      return store.state.profile.org_settings_access ? next() : next('/');
    },
  },

  {
    component: StaffIndex,
    meta: { depth: 11 },
    name: 'StaffIndex',
    path: '/staff',
    beforeEnter(_to, _from, next) {
      return store.state.profile.org_business_access ? next() : next('/');
    },
  },

  {
    component: StaffShow,
    meta: {
      beta: true,
      depth: 12,
      disableAppBar: true,
    },
    name: 'StaffShow',
    path: '/staff/:memberId',
    beforeEnter(_to, _from, next) {
      return store.state.profile.org_business_access ? next() : next('/');
    },
  },

  {
    component: SubsidyIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'SubsidyIndex',
    path: '/subsidies',
  },

  {
    component: SubsidyShow,
    meta: {
      app_bar: false,
      depth: 3,
      disableAppBar: true,
      fillHeight: true,
    },
    name: 'SubsidyShow',
    path: '/subsidies/:id',
  },

  {
    path: '/admin/subsidy_programs',
    redirect: '/admin/child_subsidy_programs',
  },

  {
    path: '/admin/subsidy_programs/:subsidyProgramId',
    redirect: '/admin/child_subsidy_programs/:subsidyProgramId',
  },

  {
    component: ChildSubsidyProgramShow,
    meta: { depth: 3 },
    path: '/admin/child_subsidy_programs/:subsidyProgramId',
    children: [
      {
        component: ChildSubsidyProgramAssignments,
        meta: { depth: 5 },
        name: 'ChildSubsidyProgramAssignments',
        path: 'assignments',
      },
      {
        component: ChildSubsidyProgramSettings,
        name: 'ChildSubsidyProgramShow',
        meta: { depth: 4 },
        path: '',
      },
      {
        component: ChildSubsidyProgramMessaging,
        meta: { depth: 5 },
        name: 'ChildSubsidyProgramMessaging',
        path: 'messaging',
      },
      {
        component: ChildSubsidyProgramQuestions,
        meta: { depth: 5 },
        name: 'ChildSubsidyProgramQuestions',
        path: 'questions',
      },
      {
        component: ChildSubsidyProgramFunding,
        meta: { depth: 5 },
        name: 'ChildSubsidyProgramFunding',
        path: 'funding',
      },
      {
        component: ChildSubsidyProgramIEPReferrals,
        meta: { depth: 5 },
        name: 'ChildSubsidyProgramIEPReferrals',
        path: 'iep_referrals',
      },
      {
        component: ChildSubsidyProgramSeats,
        meta: { depth: 5 },
        name: 'ChildSubsidyProgramSeats',
        path: 'seats',
      },
      {
        component: ChildSubsidyProgramStages,
        meta: { depth: 5 },
        name: 'ChildSubsidyProgramStages',
        path: 'stages',
      },
    ],
  },

  {
    component: ChildSubsidyProgramIndex,
    meta: { depth: 1 },
    name: 'ChildSubsidyProgramIndex',
    path: '/admin/child_subsidy_programs',
  },

  {
    component: SummaryConfigurationIndex,
    meta: { depth: 1 },
    name: 'SummaryConfigurationIndex',
    path: '/admin/summary_configurations',
  },

  {
    component: SummaryConfigurationShow,
    meta: { depth: 1 },
    name: 'SummaryConfigurationShow',
    path: '/admin/summary_configurations/:id',
  },

  {
    component: SurveyIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'SurveyIndex',
    path: '/surveys',
  },

  {
    component: SurveyShow,
    meta: {
      app_bar: false,
      depth: 3,
      disableAppBar: true,
    },
    name: 'SurveyShow',
    path: '/surveys/:surveyId',
  },

  {
    component: TaskIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'TaskIndex',
    path: '/tasks',
  },

  {
    component: TaskTypeIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'TaskTypeIndex',
    path: '/task_types',
  },

  {
    component: TeamIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'TeamIndex',
    path: '/teams',
  },

  {
    component: TeamShow,
    meta: {
      app_bar: false,
      beta: true,
      depth: 3,
      previous: {
        route: {
          name: 'TeamIndex',
        },
      },
    },
    name: 'TeamShow',
    path: '/teams/:id',
  },

  {
    component: TermIndex,
    meta: { depth: 11 },
    name: 'TermIndex',
    path: '/admin/terms',
  },

  {
    component: TermsAgreementIndex,
    meta: { depth: 11 },
    name: 'TermsAgreementIndex',
    path: '/admin/terms_agreements',
  },

  {
    component: TermsAgreementShow,
    meta: { depth: 11 },
    name: 'TermsAgreementShow',
    path: '/admin/terms_agreements/:termsAgreementId',
  },

  {
    component: TranslationIndex,
    meta: { depth: 11 },
    name: 'TranslationIndex',
    path: '/admin/translations',
  },

  {
    component: UploadNew,
    meta: { depth: 12 },
    name: 'UploadNew',
    path: '/admin/data/uploads/new',
  },

  {
    component: UploadShow,
    meta: { depth: 13 },
    name: 'UploadShow',
    path: '/admin/data/uploads/:uploadId',
  },

  {
    component: Dashboard,
    meta: { depth: 1 },
    name: 'Dashboard',
    path: '/dashboard',
  },

  {
    component: DataDashboard,
    meta: { depth: 1 },
    name: 'DataDashboard',
    path: '/data_dashboard',
  },

  {
    component: WebpageIndex,
    meta: {
      app_bar: false,
      depth: 2,
    },
    name: 'WebpageIndex',
    path: '/admin/pages',
  },

  {
    component: WebpageShow,
    meta: {
      app_bar: false,
      disableAppBar: true,
      depth: 3,
    },
    name: 'WebpageShow',
    path: '/admin/pages/:webpageId',
  },

  {
    component: Welcome,
    meta: {
      app_bar: true,
      depth: 0,
      public: true,
    },
    name: 'Welcome',
    path: '/welcome',
  },

  {
    component: WorkflowEventIndex,
    name: 'WorkflowEventIndex',
    path: '/admin/workflow_events',
  },

  {
    component: WorkflowEventShow,
    name: 'WorkflowEventShow',
    path: '/admin/workflow_events/:id',
  },

  {
    component: LoginCodes,
    meta: {
      app_bar: true,
      depth: 1,
      public: true,
    },
    name: 'LoginCode',
    path: '/login_codes/:login_code',
  },

  {
    component: PublicProviderShow,
    meta: {
      app_bar: true,
    },
    name: 'ProviderCrawlerShow',
    path: '/providers/:city/:zip/:name',
  },

  {
    component: PublicProviderShow,
    meta: {
      app_bar: true,
    },
    name: 'PublicProviderShow',
    path: '/public/providers/:providerId',
  },

  {
    component: SignOut,
    meta: {
      app_bar: true,
      depth: 10,
    },
    name: 'SignOut',
    path: '/sign_out',
  },

  {
    component: WebpageLanding,
    meta: {
      public: true,
    },
    name: 'Landing',
    path: '/',
  },

  {
    component: RegisterFactor,
    meta: {
      depth: 2,
      registration: true,
      title: 'Register MFA',
    },
    name: 'RegisterFactor',
    path: '/mfa',
  },

  {
    component: RegisterPassword,
    meta: {
      depth: 2,
      registration: true,
      title: 'Set password',
    },
    name: 'RegisterPassword',
    path: '/password',
  },

  {
    component: FallbackRouter,
    meta: {
      public: true,
    },
    path: '/:pathMatch(.*)*',
  },
];

export default createRouter({
  history: createWebHistory(import.meta.env.VUE_BASE_URL),
  routes,
});
