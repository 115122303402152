<template>
  <v-card-text v-if="assignment">
    <v-card
      class="b-1 bc-outlined-gray rounded"
      flat
      tile
    >
      <v-data-table
        v-model="expanded"
        :headers="activityHeaders"
        :items="assignmentActivityTypes"
        :loading="processing"
        item-value="name"
        no-data-text="No activities configured for course"
        disable-sort
        expand-on-click
        hide-default-footer
        hide-default-header
        hover
        show-expand
        single-expand
      >
        <template #item.completeness="{ item }">
          <span>
            {{ `${getCompleteCountByActivity(item.value)} of ${learners.length} Complete` }}
          </span>
        </template>

        <template #expanded-row="{ index, columns, item: activityRowItem }">
          <td
            :class="index !== assignmentActivityTypes.length - 1 ? 'border-b' : ''"
            :colspan="columns.length"
          >
            <v-card
              class="ma-4"
              border
              flat
            >
              <v-data-table
                @click:row="(event, { item }) => openActivityGrader(activityRowItem.value, item)"
                :headers="learnerHeaders"
                :items="learners"
                item-value="id"
                no-data-text="No learners in cohort"
                disable-sort
                hover
              >
                <template #item.name="{ item }">
                  {{ item.attributes.name }}
                </template>

                <template #item.status="{ item }">
                  <v-chip
                    v-if="activityRowItem.value === ACTIVITY_TYPES.HOMEWORK"
                    :color="`${item.homeworkComplete ? 'light-green' : 'grey'}-lighten-3`"
                    :data-testid="`homework-completion-${item.id}`"
                    size="small"
                  >
                    {{ item.homeworkComplete ? 'Complete' : 'Incomplete' }}
                  </v-chip>

                  <v-chip
                    v-if="activityRowItem.value === ACTIVITY_TYPES.COMMENT"
                    :color="`${item.commentsComplete ? 'light-green' : 'grey'}-lighten-3`"
                    :data-testid="`comment-completion-${item.id}`"
                    size="small"
                  >
                    {{ `${item.commentCount || 0} of ${assignment.required_comment_count}` }}
                  </v-chip>

                  <v-chip
                    v-if="activityRowItem.value === ACTIVITY_TYPES.REPLY"
                    :color="`${item.repliesComplete ? 'light-green' : 'grey'}-lighten-3`"
                    :data-testid="`reply-completion-${item.id}`"
                    size="small"
                  >
                    {{ `${item.replyCount || 0} of ${assignment.required_comment_reply_count}` }}
                  </v-chip>
                </template>

                <template #item.grade="{ item }">
                  <v-chip
                    v-if="item.completion?.completed"
                    :color="`${getGradeChipColor(activityRowItem.value, item)}-lighten-3`"
                    size="small"
                  >
                    {{ $t(getGradeChipText(activityRowItem.value, item)) }}
                  </v-chip>
                </template>

                <template #item.actions>
                  <div class="d-flex justify-end align-center">
                    <v-icon size="24"> chevron_right </v-icon>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <HomeworkGraderDialog
      @save="updateAssignmentCompletion"
      ref="homeworkGraderDialog"
      :assignment="assignment"
    />

    <CommentsGraderDialog
      @save="updateAssignmentCompletion"
      ref="commentsGraderDialog"
      :assignment="assignment"
    />
  </v-card-text>
</template>

<script setup>
import useEventBus from '@/shared/composables/useEventBus';
import Api from '@/shared/services/all_bright_finder';
import CommentsGraderDialog from '@/specialist/views/learning/cohort/CommentsGraderDialog.vue';
import HomeworkGraderDialog from '@/specialist/views/learning/cohort/HomeworkGraderDialog.vue';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();

const ACTIVITY_TYPES = {
  HOMEWORK: 'homework',
  COMMENT: 'comment',
  REPLY: 'reply',
};

const ACTIVITY_HEADERS = {
  HOMEWORK: {
    name: 'Homework',
    value: ACTIVITY_TYPES.HOMEWORK,
  },
  CREATE_COMMENT: {
    name: 'Post a comment',
    value: ACTIVITY_TYPES.COMMENT,
  },
  REPLY_TO_COMMENT: {
    name: 'Reply to a comment',
    value: ACTIVITY_TYPES.REPLY,
  },
};

const props = defineProps({
  assignment: {
    type: Object,
    default: null,
  },
  cohort: {
    type: Object,
    default: null,
  },
});

const activityHeaders = ref([
  {
    title: 'Activity',
    value: 'name',
  },
  {
    title: 'Completeness',
    value: 'completeness',
  },
]);
const assignmentCompletions = ref(null);
const expanded = ref([]);
const learnerHeaders = ref([
  {
    title: 'Learner',
    value: 'name',
  },
  {
    title: 'Status',
    value: 'status',
    align: 'center',
  },
  {
    title: 'Grade',
    value: 'grade',
    align: 'center',
  },
  {
    title: '',
    value: 'actions',
    align: 'right',
  },
]);
const processing = ref(false);
const homeworkGraderDialog = ref(null);
const commentsGraderDialog = ref(null);

const assignmentActivityTypes = computed(() => {
  const types = [];

  if (props.assignment?.required_homework_file_upload) types.push(ACTIVITY_HEADERS.HOMEWORK);
  if (props.assignment?.required_comment_count > 0) types.push(ACTIVITY_HEADERS.CREATE_COMMENT);
  if (props.assignment?.required_comment_reply_count > 0)
    types.push(ACTIVITY_HEADERS.REPLY_TO_COMMENT);

  return types;
});

const learners = computed(() => {
  const members = props.cohort?.included?.filter((item) => item.type === 'member') || [];

  return members.map((member) => {
    const completion = assignmentCompletions.value?.find((ac) => ac.member_id === member.id);
    let modifiedLearner = {
      ...member,
      completion,
    };

    if (!completion) return modifiedLearner;

    if (props.assignment?.required_homework_file_upload) {
      modifiedLearner = {
        ...modifiedLearner,
        homeworkComplete: completion.completed,
        homeworkGraded: completion.homework_file_upload_passed,
      };
    }

    if (props.assignment?.required_comment_count > 0) {
      modifiedLearner = {
        ...modifiedLearner,
        commentCount: completion.comment_count,
        commentsComplete: completion.completed,
        commentsGraded: completion.required_comment_passed,
      };
    }

    if (props.assignment?.required_comment_reply_count > 0) {
      modifiedLearner = {
        ...modifiedLearner,
        replyCount: completion.comment_reply_count,
        repliesComplete: completion.completed,
        repliesGraded: completion.required_comment_reply_passed,
      };
    }

    return modifiedLearner;
  });
});

watch(
  () => props.assignment.id,
  async () => {
    await loadAssignmentCompletions();
  },
  {
    immediate: true,
  },
);

function getGradeChipColor(activityType, activity) {
  let graded;

  if (activityType === ACTIVITY_TYPES.HOMEWORK) graded = activity.homeworkGraded;
  if (activityType === ACTIVITY_TYPES.COMMENT) graded = activity.commentsGraded;
  if (activityType === ACTIVITY_TYPES.REPLY) graded = activity.repliesGraded;

  switch (graded) {
    case true:
      return 'light-green';
    case false:
      return 'red';
    default:
      return 'grey';
  }
}

function getGradeChipText(activityType, activity) {
  let graded;

  if (activityType === ACTIVITY_TYPES.HOMEWORK) graded = activity.homeworkGraded;
  if (activityType === ACTIVITY_TYPES.COMMENT) graded = activity.commentsGraded;
  if (activityType === ACTIVITY_TYPES.REPLY) graded = activity.repliesGraded;

  switch (graded) {
    case true:
      return 'Pass';
    case false:
      return 'Fail';
    default:
      return 'Ungraded';
  }
}

function getCompleteCountByActivity(type) {
  if (type === ACTIVITY_TYPES.HOMEWORK)
    return learners.value.filter((learner) => learner.homeworkComplete).length;
  if (type === ACTIVITY_TYPES.COMMENT)
    return learners.value.filter((learner) => learner.commentsComplete).length;
  if (type === ACTIVITY_TYPES.REPLY)
    return learners.value.filter((learner) => learner.repliesComplete).length;

  return '-';
}

async function openActivityGrader(activityType, learner) {
  if (!learner.completion) {
    eventBus.chime('Assignment has not been started');
  } else {
    if (activityType === ACTIVITY_TYPES.HOMEWORK) {
      if (learner.completion.homework_attachment_id) {
        homeworkGraderDialog.value.open(learner, learner.completion);
      } else {
        eventBus.chime('No homework found');
      }
    }

    if ([ACTIVITY_TYPES.COMMENT, ACTIVITY_TYPES.REPLY].includes(activityType)) {
      commentsGraderDialog.value.open(learner, learner.completion, activityType);
    }
  }
}

async function loadAssignmentCompletions() {
  processing.value = true;
  const response = await Api.organization.assignmentCompletion.index({
    assignment_id: route.params.assignmentId,
  });

  assignmentCompletions.value = response?.data || [];
  processing.value = false;
}

async function updateAssignmentCompletion(assignmentCompletion) {
  const response = await Api.organization.assignmentCompletion.update(
    assignmentCompletion.id,
    assignmentCompletion,
  );

  if (response.status === 200) {
    eventBus.chime('Grade saved');
    await loadAssignmentCompletions();
  } else {
    eventBus.chime(response?.data?.errors[0] || 'An error occurred');
  }
}
</script>
