<template>
  <div>
    <template v-if="claims.length > 0">
      <v-card
        flat
        tile
      >
        <v-card-text class="pb-0">
          <v-card
            class="bb-1 bc-very-light-gray"
            flat
            tile
          >
            <v-card-text class="pt-0 pb-3 fs-12">
              <v-row
                class="d-flex"
                dense
              >
                <div>
                  <span class="fw-600">{{ $t('Total funding amount') + ': ' }}</span>
                  <span class="ms-2">{{ currency(claimOwner.award_amount) }}</span>
                </div>
                <v-spacer />

                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <div>
                      <v-icon
                        v-bind="props"
                        class="d-inline pe-2 pb-1"
                      >
                        info
                      </v-icon>
                      <span class="fw-600">{{ $t('Remaining funding amount:') + ' ' }}</span>
                      <span class="ms-2">
                        {{ currency(claimOwner.award_amount - claimOwner.approved_amount) }}
                      </span>
                    </div>
                  </template>
                  <span>
                    {{ $t('Unapproved claims are not included in this amount.') }}
                  </span>
                </v-tooltip>
                <v-spacer />
              </v-row>
            </v-card-text>
          </v-card>

          <v-data-table
            @click:row="viewClaim"
            :headers="tableHeaders"
            :items="claims"
            item-key="id"
            dense
            disable-sort
            fixed-header
          >
            <template #item.locked="{ item }">
              <div class="d-flex justify-start align-center">
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-btn
                      @click.stop.prevent="toggleClaimLock(item)"
                      v-bind="props"
                      :disabled="item.approved || item.denied"
                      color="primary"
                      data-cy="lock-button"
                      size="x-small"
                      variant="text"
                      icon
                    >
                      <v-icon>{{ item.locked ? 'lock' : 'lock_open' }}</v-icon>
                    </v-btn>
                  </template>

                  <span v-if="item.locked">
                    {{ $t('The claim is locked. The applicant cannot make changes.') }}
                  </span>

                  <span v-else>
                    {{ $t('The claim is unlocked. The applicant can make changes.') }}
                  </span>
                </v-tooltip>
              </div>
            </template>

            <template #item.id="{ item }">
              {{ item.id.slice(0, 6).toUpperCase() }}
            </template>

            <template #item.requested_amount="{ item }">
              {{ currency(item.requested_amount) }}
            </template>

            <template #item.approved_amount="{ item }">
              <span v-if="item.approved">{{ currency(item.approved_amount) }}</span>
            </template>

            <template #item.status="{ item }">
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <span
                    v-bind="props"
                    :class="getStatusClasses(item)"
                    class="rounded b-1 px-3 py-1"
                  >
                    <span v-if="item.approved">{{ $t('Approved') }}</span>
                    <span v-else-if="item.denied">{{ $t('Denied') }}</span>
                    <span v-else>{{ $t('Submitted') }}</span>
                  </span>
                </template>
                <span>
                  <LongDate
                    :date="item.approved_at || item.denied_at || item.submitted_at"
                    short-month
                  />
                </span>
              </v-tooltip>
            </template>

            <template #item.created_at="{ item }">
              <LongDate
                :date="item.created_at"
                short-month
              />
            </template>

            <template #item.updated_at="{ item }">
              <LongDate
                :date="item.updated_at"
                short-month
              />
            </template>

            <template #item.actions>
              <div class="d-flex justify-end align-center">
                <v-icon
                  class="fs-22 fw-500"
                  end
                >
                  chevron_right
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <NullState v-else />

    <ClaimReviewDialog
      @change="loadClaims()"
      ref="claimReviewDialog"
      :family-subsidy="familySubsidy"
    />
  </div>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ClaimReviewDialog from '@/shared/components/ClaimReviewDialog.vue';
import LongDate from '@/shared/components/LongDate.vue';
import NullState from '@/shared/components/NullState.vue';
import { currency } from '@/plugins/filters';

const props = defineProps({
  familySubsidy: {
    type: Object,
    default: null,
  },
  grant: {
    type: Object,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
});

const claims = ref([]);
const claimReviewDialog = ref(null);

const claimOwner = computed(() => {
  return props.familySubsidy || props.grant;
});

const tableHeaders = computed(() => {
  let headers = [
    {
      title: 'Claim / ID',
      value: 'id',
      align: 'center',
    },
    {
      title: 'Requested',
      key: 'requested_amount',
      align: 'center',
    },
    {
      title: 'Approved',
      key: 'approved_amount',
      align: 'center',
    },
    {
      title: 'Status',
      value: 'status',
      align: 'center',
    },
    {
      title: 'Created at',
      key: 'created_at',
      align: 'center',
    },
    {
      title: 'Last updated',
      key: 'updated_at',
      align: 'center',
    },
    {
      title: '',
      value: 'actions',
      align: 'right',
    },
  ];

  if (!props.readonly)
    headers.unshift({
      title: '',
      value: 'locked',
      width: '5%',
    });
  return headers;
});

onMounted(loadClaims);

function getStatusClasses(claim) {
  if (claim.approved) return 'bc-primary c-primary';
  if (claim.denied) return 'bc-red c-red';
  return 'bc-gray c-gray';
}

async function loadClaims() {
  const params = {
    family_subsidy_id: props.familySubsidy?.id,
    grant_id: props.grant?.id,
  };
  const resp = await api.organization.claim.index(params);
  if (resp?.status !== 200) return;

  claims.value = resp.data;
}

async function toggleClaimLock(claim) {
  const resp = await api.organization.claim.update(claim.id, { locked: !claim.locked });
  if (resp?.status !== 200) return;
  await loadClaims();
}

function viewClaim(_event, { item: claim }) {
  claimReviewDialog.value.open(claim.id);
}
</script>
